var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('c-box', {
    attrs: {
      "as": "nav",
      "display": "flex",
      "gap": "16px"
    }
  }, [_c('c-box', {
    attrs: {
      "as": "button",
      "height": "25px",
      "width": "25px",
      "min-width": "25px",
      "border-radius": "100%",
      "background-color": "primary.50",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center",
      "_hover": {
        backgroundColor: 'primary.100'
      },
      "_active": {
        backgroundColor: 'primary.200'
      }
    },
    on: {
      "click": _vm.handlePrevPage
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-chevron-left.svg'),
      "height": "80%",
      "width": "80%",
      "stroke": "#008C81"
    }
  })], 1), _c('c-box', {
    attrs: {
      "as": "ul",
      "display": "flex",
      "list-style-type": "none",
      "gap": "16px"
    }
  }, _vm._l([].concat(_vm.firstPagination, _vm.middlePagination, _vm.lastPagination), function (i) {
    return _c('c-box', {
      key: i === '...' ? Math.random() : i,
      attrs: {
        "as": "li"
      }
    }, [i === '...' ? _c('c-box', {
      attrs: {
        "height": "24px",
        "width": "24px",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center"
      }
    }, [_vm._v(" " + _vm._s(i) + " ")]) : _c('c-button', {
      attrs: {
        "background-color": _vm.currentPage === i ? 'superLightGray.900' : 'inherit',
        "font-size": "14px",
        "font-weight": "400",
        "height": "24px",
        "width": "24px",
        "padding": "0",
        "min-width": "24px",
        "border-radius": "100%",
        "display": "flex",
        "justify-content": "center",
        "align-items": "center"
      },
      on: {
        "click": function click($event) {
          return _vm.handlePageClick(i);
        }
      }
    }, [_vm._v(" " + _vm._s(i) + " ")])], 1);
  }), 1), _c('c-box', {
    attrs: {
      "as": "button",
      "height": "25px",
      "width": "25px",
      "min-width": "25px",
      "border-radius": "100%",
      "background-color": "primary.50",
      "display": "flex",
      "justify-content": "center",
      "align-items": "center",
      "_hover": {
        backgroundColor: 'primary.100'
      },
      "_active": {
        backgroundColor: 'primary.200'
      }
    },
    on: {
      "click": _vm.handleNextPage
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-chevron-right.svg'),
      "height": "80%",
      "width": "80%",
      "stroke": "#008C81"
    }
  })], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }